@import "normalize.css";

body {
  font-family: sans-serif;
  height: 100vh;
  max-height: 100vh;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f8f9f9;
}

.frame {
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
}

.frame-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.test {
  display: flex;
  flex-grow: 1;
  background: #6b7280;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-grow: 0;
  border-bottom: 3px solid #695d9d;
  padding: 1rem 2rem;
}

.footer {
  width: 100%;
  background-color: blue;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 0;
}

.handle {
  width: 1rem;
  cursor: ew-resize;
  background-color: #f1f3f4;
  display: flex;
}

#button-copy {
  background-color: transparent;
  border-color: white;
  color: black;
  font-weight: 600;
}

.select-button {
  border-radius: 0.375rem;
  background-color: transparent;
}

.button {
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;

  & > svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }

  & > img {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}

.button-container {
  display: inline-flex;
  color: #000;
  list-style: none;
}

ul li {
  display: block;
  position: relative;
  float: left;
}

.selected-button::before {
  content: "";
  position: absolute;
  top: 50px;
  left: 65%;
  margin-left: -15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #695d9d;
}

.active {
  color: #695d9d;
}

@media screen and (max-width: 600px) {
  ul li {
    display: none;
  }
}
